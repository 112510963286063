$(document).ready(function () {
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': csrfToken
        }
    });

    $('.prijavi_kot').change(function () {
        var value = $(this).val();
        if (value == 'posameznik') {
            $('#forma-posameznik').removeClass('hidden');
            $('#forma-podjetje').addClass('hidden');
        } else {
            $('#forma-podjetje').removeClass('hidden');
            $('#forma-posameznik').addClass('hidden');
        }
    });

    $('.prijava-single').click(function (e) {
        e.preventDefault();

        var link = $(this);

        $.post('/tekmovanja/add-single-user', {
            tekmovanje_id: $(link).attr('data-id')
        }, function (response) {
            $('.table-container').html(response);
            $(link)
                .parent()
                .parent()
                .remove();
        });
    });

    $('input[name="pomocnik"]').change(function () {
        if ($(this).val() == 1) {
            $('#pomocnik-field')
                .removeClass('hidden');
        } else {
            $('#pomocnik-field')
                .addClass('hidden');
        }
    });

    $('input[name="mentor"]').change(function () {
        if ($(this).val() == 1) {
            $('#mentor-field')
                .removeClass('hidden');
        } else {
            $('#mentor-field')
                .addClass('hidden');
        }
    });

    $('.remove-prijava').click(function (e) {
        e.preventDefault();

        var button = $(this);

        if (!confirm('Ali želite odstraniti prijavo s tekmovanja "' + $(button).attr('data-tekmovanje') + '"?')) {
            return;
        }

        $.post('/prijave/odstrani', {
            prijava_id: $(button).attr('data-id')
        }, function () {
            window.location.reload(true);
        });
    });
});